import React from "react";
import Logo from "../../assets/images/logo-2x.png";
import LoginForm from "./LoginForm";

const Login = () => {
  return (
    <main className="login-page">
      <div className="branding-side">
        <img className="logo" src={Logo} alt="Logo" />
        <h1 className="brand-title">Cloud Storage</h1>
        <p className="brand-desc">
          Welcome! Your secure access to our platform is our priority.
        </p>
      </div>
      <div className="form-side">
        <LoginForm logo={Logo} />
      </div>
    </main>
  );
};

export default Login;
