import { CloudDownloadOutlined, DeleteFilled } from "@ant-design/icons";
import { Card, Popconfirm } from "antd";
import { clsx } from "clsx";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { downloadAttachment, getImageByUrl } from "../../Common";
import { ReactComponent as FolderIcon } from "../../assets/svg/folder-icon.svg";
import { deleteFile, getDirectory, getFiles } from "../../redux/action/file";

const FileCard = ({
  name,
  isSub = false,
  icon = true,
  isNav = false,
  directory,
  thumbnailUrl,
  isHeader = false,
  actualImage,
  ext,
  fileId,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [thumbnail, setThumbnail] = useState("");

  const handleClick = async (name) => {
    if (isNav === "folder") {
      const fileName = name?.toLowerCase();
      await getDirectory(fileName);
      navigate(`/cloud/${fileName}`);
    } else if (isNav === "file") {
      const path = location.pathname.split("/").pop();
      localStorage.setItem(
        "fileDirectory",
        JSON.stringify({ path, directory })
      );
      getFiles(path, directory);
      navigate("/cloud/files");
    }
  };

  const cardStyle = {
    background: "$primary-color",
    background: `url(${thumbnail})`,
  };

  useEffect(() => {
    const getThumb = async () => {
      const res = await getImageByUrl(thumbnailUrl);
      setThumbnail(res);
    };
    if (isSub) getThumb();
  }, []);

  const handleDownloadAttachment = async (e) => {
    e.stopPropagation();
    const res = await getImageByUrl(actualImage);
    if (res) downloadAttachment(res, name, ext);
  };

  const handleDeleteFile = async (e, fileId) => {
    e.stopPropagation();
    const { path, directory } = JSON.parse(
      localStorage.getItem("fileDirectory")
    );
    const body = [`${fileId}`];
    await deleteFile(body);
    await getFiles(path, directory);
  };

  return (
    <div className={clsx("file-card", name)}>
      <Card
        bordered={false}
        onClick={() => handleClick(name)}
        style={isSub ? cardStyle : null}
        className="card-container"
        title={
          <div
            className={clsx("flex-header card-header", { hidden: !isHeader })}
          >
            <Popconfirm
              title="Delete the file"
              description="Are you sure to delete this file?"
              okText="Yes"
              cancelText="No"
              onConfirm={(e) => handleDeleteFile(e, fileId)}
            >
              <DeleteFilled onClick={(e) => e.stopPropagation()} />
            </Popconfirm>
            {/* <CloudDownloadOutlined
              onClick={(e) => handleDownloadAttachment(e)}
            /> */}
          </div>
        }
      >
        <div className="card-body">
          <div className={clsx("icon", { hidden: isSub && !icon })}>
            <FolderIcon />
          </div>
          <div
            title={name}
            className={clsx("file-name ellipse", { "sub-file-name": isSub })}
          >
            {name}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default FileCard;
