import {
  DeleteOutlined,
  LogoutOutlined,
  PlusOutlined,
  SearchOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Dropdown, Input, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getProfileImage, handleLogout } from "../../Common";
import { ReactComponent as CloudUploadIcon } from "../../assets/svg/cloud-upload-icon.svg";
import { deleteUser, getUserProfile } from "../../redux/action/user";
import CustomModal from "../CustomModal";
import UpdateProfile from "./UpdateProfile";
import ResponsiveMenu from "../Aside/ResponsiveMenu";
import { ReactComponent as Hamburger } from "../../assets/svg/hamburger.svg";
import { useLocation } from "react-router-dom";

const Header = ({ isMainHeader = false }) => {
  const location = useLocation();
  const { user, profile_image } = useSelector((state) => state.userSlice);
  const [logoutModal, setLogoutModal] = useState(false);
  const [updateProfile, setUpdateProfile] = useState(false);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);

  const handleDeleteUser = async () => {
    await deleteUser();
    await handleLogout();
    setDeleteUserModal(false);
  };

  useEffect(() => {
    getUserProfile();
    getProfileImage();
  }, []);

  useEffect(() => {
    if (mobileMenu) setMobileMenu(false);
  }, [location?.pathname]);

  const items = [
    {
      key: "profile",
      label: (
        <span onClick={() => setUpdateProfile(true)}>
          <UserOutlined /> Update Profile
        </span>
      ),
    },
    {
      key: "delete",
      label: (
        <span onClick={() => setDeleteUserModal(true)}>
          <DeleteOutlined /> Delete User
        </span>
      ),
    },
    {
      key: "logout",
      label: (
        <span onClick={() => setLogoutModal(true)}>
          <LogoutOutlined /> Log Out
        </span>
      ),
    },
  ];

  return (
    <>
      <header className="main-header">
        <div className="left-side">
          <Hamburger
            className="hamburger"
            onClick={() => setMobileMenu(true)}
          />
          {isMainHeader ? (
            <>
              <div className="title greeting">Hi, {user?.name} 👋</div>
              <div className="title mobile-title ellipse">{user?.name}</div>
            </>
          ) : (
            <div className="search-bar">
              <Input prefix={<SearchOutlined />} placeholder="Search here" />
            </div>
          )}
        </div>

        <div className="right-side">
          {!isMainHeader && (
            <>
              <Button
                type="text"
                icon={<PlusOutlined />}
                // onClick={() => setUploadModal(true)}
              >
                Create New
              </Button>
              <Button
                type="primary"
                icon={<CloudUploadIcon />}
                // onClick={() => setUploadModal(true)}
              >
                Upload
              </Button>
            </>
          )}
          <Dropdown menu={{ items }} trigger="click">
            <Avatar size={44} icon={<UserOutlined />} src={profile_image} />
          </Dropdown>
        </div>
      </header>
      {logoutModal && (
        <CustomModal
          title="Sign Out"
          visible={logoutModal}
          okBtnText="Sign Out"
          handleCancel={() => setLogoutModal(false)}
          handleOk={handleLogout}
          width={400}
        >
          Are you sure you want to Logout your account?
        </CustomModal>
      )}
      {updateProfile && (
        <UpdateProfile
          updateProfile={updateProfile}
          setUpdateProfile={setUpdateProfile}
        />
      )}
      {deleteUserModal && (
        <CustomModal
          title="Delete User"
          visible={deleteUserModal}
          okBtnText="Delete User"
          handleCancel={() => setDeleteUserModal(false)}
          handleOk={handleDeleteUser}
          width={400}
        >
          Are you sure you want to delete your account? This action cannot be
          undone
        </CustomModal>
      )}

      <ResponsiveMenu open={mobileMenu} setOpen={setMobileMenu} />
    </>
  );
};

export default Header;
