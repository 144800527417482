import { Col, Empty, Row } from "antd";
import React from "react";
import FileCard from "../../../components/FileCard";
import { useSelector } from "react-redux";

const SubFolder = () => {
  const { files } = useSelector((state) => state.fileSlice);
  return (
    <section className="images-page">
      {files?.length ? (
        <Row gutter={[16, 16]}>
          {files.map((file) => {
            const folderName =
              file?.folderName?.split("/").pop() + ` (${file.count})`;
            return (
              <Col key={file.file.fileId} xs={24} sm={12} md={8} lg={5} xl={5}>
                <FileCard
                  isSub
                  icon={false}
                  {...file}
                  name={folderName}
                  directory={file?.folderName}
                  thumbnailUrl={file?.file?.thumbnailUrl}
                  isNav="file"
                  actualImage={file?.originalUrl}
                  ext={file.originalFileExt}
                />
              </Col>
            );
          })}
        </Row>
      ) : (
        <Empty description="No files found" />
      )}
    </section>
  );
};

export default SubFolder;
