import { handleDeleteRequest, handleGetRequest } from ".";
import { URI } from "../../constants/URI";
import {
  setEncKey,
  setFileType,
  setFiles,
  setSubFiles,
  setTotalVolume,
} from "../slices/fileSlice";
import { store } from "../store";

export const getFileCount = async () => {
  const res = await handleGetRequest(URI.file_type);
  if (res?.response) store.dispatch(setFileType(res.response.data));
};

export const getTotalVolume = async () => {
  const res = await handleGetRequest(URI.total_volume, null, {
    loading: false,
  });
  if (res?.response)
    store.dispatch(setTotalVolume(res.response.totalUsedVolume));
};

export const getDirectory = async (type) => {
  const res = await handleGetRequest(URI.get_directory, null, {
    toast: false,
    params: {
      type,
    },
  });
  if (res.response) store.dispatch(setFiles(res.response));
};

export const getFiles = async (type, directory) => {
  const res = await handleGetRequest(URI.get_files, null, {
    toast: false,
    params: {
      type,
      directory,
    },
  });
  if (res.response) store.dispatch(setSubFiles(res.response.files));
};

export const getEncFileKeys = async () => {
  const res = await handleGetRequest(URI.encryption_keys_file, null, {
    toast: false,
    loading: false,
  });
  if (res) store.dispatch(setEncKey(res?.keys));
};

export const deleteFile = async (body) => {
  const res = await handleDeleteRequest(URI.delete_files, body);
  return res;
};
