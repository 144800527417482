import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: JSON.parse(localStorage.getItem("user")),
  packages: {},
  storage: 0,
  profile_image: localStorage.getItem("profile_image") || "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      const { user, packages, storage, profile_image } = action.payload;
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("profile_image", profile_image);
      state.user = user;
      state.packages = packages;
      state.storage = storage;
      state.profile_image = profile_image;
    },
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
