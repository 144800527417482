import React from "react";
import { Form, Input, Button, Checkbox } from "antd";
import GoogleAuth from "./GoogleAuth";

const LoginForm = () => {
  const onFinish = (values) => {
    console.log("Received values:", values);
  };

  return (
    <div className="form-container">
      <div className="form-title">Login</div>
      <Form
        name="login-form"
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              type: "email",
              message: "The input is not a valid email!",
            },
            { required: true, message: "Please input your email!" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password />
        </Form.Item>

        <div className="single-column">
          <Form.Item
            name="remember"
            valuePropName="checked"
            className="remember"
          >
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <Form.Item>
            <a href="/" className="forgot-pass">
              Forgot Password?
            </a>
          </Form.Item>
        </div>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Login
          </Button>
        </Form.Item>
      </Form>
      <p className="or">or</p>
      <GoogleAuth />
    </div>
  );
};

export default LoginForm;
