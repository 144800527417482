import { Col, Empty, Row } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import FileCard from "../../../components/FileCard";
import { getEncFileKeys } from "../../../redux/action/file";

const SubFile = () => {
  const { sub_files } = useSelector((state) => state.fileSlice);
  useEffect(() => {
    getEncFileKeys();
  }, []);

  return (
    <section className="images-page">
      {sub_files?.length ? (
        <Row gutter={[16, 16]}>
          {sub_files.map((file) => {
            return (
              <Col key={file.fileId} xs={24} sm={12} md={8} lg={5} xl={5}>
                <FileCard
                  isSub
                  icon={false}
                  {...file}
                  name={file?.name}
                  thumbnailUrl={file.thumbnailUrl}
                  actualImage={file?.originalUrl}
                  ext={file.originalFileExt}
                  isHeader
                  fileId={file.fileId}
                />
              </Col>
            );
          })}
        </Row>
      ) : (
        <Empty description="No files found" />
      )}
    </section>
  );
};

export default SubFile;
