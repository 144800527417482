import { Spin } from "antd";
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import Routes from "./Routes";
import "./styles/index.scss";
import "./styles/styles.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";

function App() {
  const { loading } = useSelector((state) => state.loadingSlice);
  return (
    <div className="App">
      <LoadingOverlayWrapper
        spinner={<Spin size="large" />}
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 0.5)",
            height: "100dvh",
          }),
        }}
      >
        <Routes />
      </LoadingOverlayWrapper>
      <ToastContainer />
    </div>
  );
}

export default App;
