import React from "react";
import { Modal, Button } from "antd";

const CustomModal = ({
  title = "Modal Title",
  visible,
  handleOk,
  handleCancel,
  footer = true,
  cancelBtnText = "Cancel",
  okBtnText = "Confirm",
  children,
  width = 600,
}) => {
  return (
    <Modal
      title={title}
      open={visible}
      onCancel={handleCancel}
      className="modal"
      centered
      width={width}
      footer={
        footer && (
          <div className="logout-modal-footer">
            <Button key="cancel" onClick={handleCancel}>
              {cancelBtnText}
            </Button>

            <Button
              key="logout"
              type="primary"
              htmlType="submit"
              onClick={handleOk}
            >
              {okBtnText}
            </Button>
          </div>
        )
      }
    >
      {children}
    </Modal>
  );
};

export default CustomModal;
