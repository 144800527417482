import { handlePostRequest } from ".";
import { URI } from "../../constants/URI";
import { setAuth } from "../slices/authSlice";
import { store } from "../store";

export const postGoogleUserData = async (body) => {
  const res = await handlePostRequest(URI.auth, body);
  if (res) {
    store.dispatch(setAuth(res.response.token));
  }
};
