import { CloseOutlined } from "@ant-design/icons";
import { Drawer } from "antd";
import React from "react";
import Aside from ".";

const ResponsiveMenu = ({ open, setOpen }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Drawer
      closable={false}
      open={open}
      placement="left"
      className="mobile-menu"
      onClose={handleClose}
      key="menu-drawer"
    >
      <>
        <CloseOutlined className="close-menu" onClick={handleClose} />
        <Aside isMobileMenu />
      </>
    </Drawer>
  );
};

export default ResponsiveMenu;
