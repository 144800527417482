import { Card } from "antd";
import React from "react";

const CardDetail = ({ icon, bgColor, name, count, size, ext }) => {
  return (
    <Card loading={false} className="card-main" style={{ background: bgColor }}>
      <div className="card-inner">
        <div className="icon">
          <img src={icon} alt={name + " icon"} />
        </div>
        <div className="card-title">{name}</div>
        <div className="card-subtitle">
          {count} {name}
        </div>
        <div className="card-desc">
          {size.toFixed(2)} {ext}
        </div>
      </div>
    </Card>
  );
};

export default CardDetail;
