import Cloud from "../pages/Cloud";
import Dashboard from "../pages/Dashboard";
import Favorite from "../pages/Favorite";
import Recent from "../pages/Recent";

import { ReactComponent as CloudIcon } from "../assets/svg/cloud.svg";
import { ReactComponent as DashboardIcon } from "../assets/svg/dashboard.svg";
import { ReactComponent as FavoriteIcon } from "../assets/svg/favorite.svg";
import { ReactComponent as RecentIcon } from "../assets/svg/recent.svg";
import SubFolder from "../pages/Cloud/SubFolder";
import SubFile from "../pages/Cloud/SubFile";

export const routesArray = [
  {
    icon: <DashboardIcon />,
    name: "Dashboard",
    path: "/",
    element: <Dashboard />,
  },
  {
    icon: <CloudIcon />,
    name: "My Cloud",
    path: "/cloud",
    element: <Cloud />,
  },
  // {
  //   icon: <RecentIcon />,
  //   name: "Recent",
  //   path: "/recent",
  //   element: <Recent />,
  // },
  // {
  //   icon: <FavoriteIcon />,
  //   name: "Favorite",
  //   path: "/favorite",
  //   element: <Favorite />,
  // },
];

export const subPages = [
  {
    name: "Images",
    path: "/cloud/images",
    element: <SubFolder />,
  },
  {
    name: "Videos",
    path: "/cloud/videos",
    element: <SubFolder />,
  },
  {
    name: "Docs",
    path: "/cloud/docs",
    element: <SubFolder />,
  },
  {
    name: "Contacts",
    path: "/cloud/contacts",
    element: <SubFolder />,
  },
  {
    name: "Audios",
    path: "/cloud/audios",
    element: <SubFolder />,
  },
  {
    name: "Files",
    path: "/cloud/files",
    element: <SubFile />,
  },
];
