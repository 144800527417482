const baseUrl = process.env.REACT_APP_CS_APP_URL;

export const URI = {
  auth: `${baseUrl}/login`,
  user: `${baseUrl}/profile`,
  update_profile: `${baseUrl}/update-profile`,
  delete_user: `${baseUrl}/user`,
  get_profile_image: `${baseUrl}/download-profile-picture`,
  file_type: `${baseUrl}/file-count`,
  total_volume: `${baseUrl}/file-volume`,
  get_directory: `${baseUrl}/directory`,
  get_files: `${baseUrl}/directory/files/`,
  delete_files: `${baseUrl}/file`,
  encryption_keys_file: `${baseUrl}/encryption-keys-file`,
};
