import { Form, Input } from "antd";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { updateUserProfile } from "../../../redux/action/user";
import CustomModal from "../../CustomModal";

const UpdateProfile = ({ updateProfile, setUpdateProfile }) => {
  const { user } = useSelector((state) => state.userSlice);
  const formRef = useRef();

  const handleFormSubmit = async (values) => {
    const body = {
      name: values.name,
    };
    if (values.name !== user.name) await updateUserProfile(body);
    setUpdateProfile(false);
  };

  const handleOk = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  };

  return (
    <CustomModal
      visible={updateProfile}
      title="Update Name"
      okBtnText="Update Name"
      width={400}
      handleOk={handleOk}
      handleCancel={() => {
        setUpdateProfile(false);
      }}
    >
      <Form
        ref={formRef}
        layout="vertical"
        initialValues={{ name: user?.name }}
        onFinish={handleFormSubmit}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Name is required" }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </CustomModal>
  );
};

export default UpdateProfile;
