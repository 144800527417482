import { Col, Row } from "antd";
import React from "react";
import FileCard from "../../components/FileCard";

const Cloud = () => {
  const rootFolder = [
    { name: "Images" },
    { name: "Videos" },
    { name: "Contacts" },
    { name: "Docs" },
    { name: "Audios" },
  ];

  return (
    <section className="cloud-page">
      <Row gutter={[16, 16]}>
        {rootFolder.map((folder, i) => {
          return (
            <Col key={folder.name} xs={24} sm={12} md={8} lg={5} xl={5}>
              <FileCard {...folder} isNav="folder" />
            </Col>
          );
        })}
      </Row>
    </section>
  );
};

export default Cloud;
