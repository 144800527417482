import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Audios from "../../assets/svg/audio-icon.svg";
import Contacts from "../../assets/svg/contacts-icon.svg";
import Document from "../../assets/svg/doc-icon.svg";
import Image from "../../assets/svg/image-icon.svg";
import Videos from "../../assets/svg/video-icon.svg";
import { getFileCount, getTotalVolume } from "../../redux/action/file";
import CardDetail from "./Card";
import ProgressChart from "./ProgressChart";
import { useNavigate } from "react-router-dom";

const MediaCards = () => {
  const { file_type, total_volume } = useSelector((state) => state.fileSlice);
  const [filesData, setFilesData] = useState([]);
  const volume = parseFloat(total_volume).toFixed(2);
  const navigate = useNavigate();

  const updateArray = (arr) => {
    return arr?.map((item) => {
      switch (item.name) {
        case "audios":
          return { ...item, icon: Audios, bgColor: "rgba(254, 68, 202, 0.2)" };
        case "contacts":
          return {
            ...item,
            icon: Contacts,
            bgColor: "rgba(68, 254, 142, 0.2)",
          };
        case "docs":
          return { ...item, icon: Document, bgColor: "rgba(254, 79, 68, 0.2)" };
        case "images":
          return { ...item, icon: Image, bgColor: "rgba(68, 176, 254, 0.2)" };
        case "videos":
          return { ...item, icon: Videos, bgColor: "rgba(254, 168, 68, 0.2)" };
        default:
          return item;
      }
    });
  };

  useEffect(() => {
    getFileCount();
    getTotalVolume();
  }, []);

  useEffect(() => {
    setFilesData(updateArray(file_type));
  }, [file_type]);
  return (
    <div className="cards-container">
      <div className="chart-container">
        <ProgressChart volume={volume} />
      </div>
      {filesData?.map((data) => (
        <CardDetail key={data.name} {...data} />
      ))}
    </div>
  );
};

export default MediaCards;
