import React from "react";

const cleanPercentage = (percentage) => {
  const tooLow = !Number.isFinite(+percentage) || percentage < 0;
  const tooHigh = percentage > 100;
  return tooLow ? 0 : tooHigh ? 100 : +percentage;
};

const Circle = ({ color, pct }) => {
  const r = 70;
  const circ = 2 * Math.PI * r;
  const strokePct = ((100 - pct) * circ) / 100;
  return (
    <circle
      r={r}
      cx={100}
      cy={100}
      fill="transparent"
      stroke={strokePct !== circ ? color : ""}
      strokeWidth={"1.5rem"}
      strokeDasharray={circ}
      strokeDashoffset={pct ? strokePct : 0}
      strokeLinecap="round"
    ></circle>
  );
};

const Text = ({ percentage, storage }) => {
  return (
    <g>
      <text
        x="50%"
        y="45%"
        dominantBaseline="central"
        textAnchor="middle"
        fontSize={"1.2em"}
        fontWeight={600}
      >
        {percentage.toFixed(0)}%
      </text>
      <text
        x="50%"
        y="55%"
        dominantBaseline="central"
        textAnchor="middle"
        fontSize={"1em"}
      >
        Total: {storage}
      </text>
    </g>
  );
};

const ProgressChart = ({ volume }) => {
  const totalVolume = 10;
  const remainingVolume = totalVolume - volume;
  const percentage = (remainingVolume / totalVolume) * 100;
  const pct = cleanPercentage(percentage);
  return (
    <div className="chart-wrapper">
      <svg width={200} height={200} className="pie-chart">
        <g transform={`rotate(-90 ${"100 100"})`}>
          <Circle color="rgba(174, 175, 182, 1)" />
          <Circle color="rgba(68, 103, 254, 1)" pct={pct} />
        </g>
        <Text percentage={pct} storage={totalVolume + "GB"} />
      </svg>
      <div className="legend-container">
        <div className="used">Used: {volume} GB</div>
        <div className="remain">Left: {remainingVolume} GB</div>
      </div>
    </div>
  );
};

export default ProgressChart;
