import React from "react";
import { useSelector } from "react-redux";
import { Route, Routes as Router, useNavigate } from "react-router-dom";
import Layout from "../components/Layout";
import { routesArray, subPages } from "../constants/Routes";
import Login from "../pages/Login";
import NotFound from "../pages/NotFound";
import PrivateRoute from "./PrivateRoute";

const Routes = () => {
  const isAuthenticated = useSelector((state) => state?.authSlice?.auth);

  const navigate = useNavigate(); // Hook to access the navigation function

  const handleGoBack = () => {
    navigate(-1); // This function takes the user back to the previous page in history
  };

  return (
    <Router>
      {!isAuthenticated ? (
        <Route path="/" element={<Login />} />
      ) : (
        <Route element={<PrivateRoute isLogged={isAuthenticated} />}>
          {routesArray?.map((route) => (
            <Route
              key={route?.name}
              path={route?.path}
              element={<Layout title={route.name}>{route?.element}</Layout>}
            />
          ))}
          {subPages?.map((sub) => {
            return (
              <Route
                key={sub.name}
                path={sub.path}
                element={
                  <Layout title={sub.name} goBack={handleGoBack}>
                    {sub.element}
                  </Layout>
                }
              />
            );
          })}
        </Route>
      )}
      <Route path="*" element={<NotFound />} />
    </Router>
  );
};

export default Routes;
