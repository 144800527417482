import { store } from "../store";
import axios from "axios";
import { showErrorToast, showToast } from "../../components/NotificationToast";
import { setLoading } from "../slices/loadingSlice";
import { handleLogout } from "../../Common";

const baseURL = process.env.REACT_APP_CS_APP_URL;

const axiosInstance = axios.create({
  baseURL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    // Attach the token to the Authorization header
    const authToken = store.getState().authSlice.auth;
    config.headers.Authorization = authToken;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const handleRequest =
  (method) =>
  async (
    url,
    data,
    config = { loading: true, toast: true, throwError: true }
  ) => {
    try {
      const { loading } = config;
      if (loading || loading === undefined) store.dispatch(setLoading(true));

      const response = await axiosInstance.request({
        method,
        url,
        data,
        ...{ ...config, signal: config.source?.signal },
      });

      if (method !== "get" && config.toast && response?.data) {
        showToast(response?.data?.message);
      }

      return config.responseType === "blob" ? response : response?.data;
    } catch (error) {
      if (error.code === "ERR_CANCELED") return error.code;

      if (error?.response?.status === 401) {
        handleLogout();
        return;
      }

      if (config.throwError || config.throwError === undefined) {
        showErrorToast(error.message);
      }

      return;
    } finally {
      store.dispatch(setLoading(false));
    }
  };

const handleGetRequest = handleRequest("get");
const handlePostRequest = handleRequest("post");
const handlePutRequest = handleRequest("put");
const handleDeleteRequest = handleRequest("delete");
const handlePatchRequest = handleRequest("patch");

export {
  handleDeleteRequest,
  handleGetRequest,
  handlePatchRequest,
  handlePostRequest,
  handlePutRequest,
};
