import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import authSlice from "./slices/authSlice";
import loadingSlice from "./slices/loadingSlice";
import testSlice from "./slices/testSlice";
import userSlice from "./slices/userSlice";
import fileSlice from "./slices/fileSlice";

export const store = configureStore({
  reducer: {
    testSlice,
    loadingSlice,
    authSlice,
    userSlice,
    fileSlice,
  },
});

export const useAppDispatch = () => useDispatch();

export const AppDispatch = store.dispatch;

export const RootState = store.getState;
