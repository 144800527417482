import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  auth: localStorage.getItem("auth"),
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.auth = action.payload;
      localStorage.setItem("auth", state.auth);
    },
  },
});

export const { setAuth, updateCamp } = authSlice.actions;

export default authSlice.reducer;
