import { LeftOutlined } from "@ant-design/icons";
import React from "react";
import Aside from "../Aside";
import Header from "../Header";

const Layout = ({ title, children, goBack }) => {
  return (
    <div className="Layout">
      <Aside />
      <main className="main">
        <Header isMainHeader={true} />
        <div className="container">
          <div className="page-title">
            {goBack && <LeftOutlined title="Go Back" onClick={goBack} />}
            {title}
          </div>
          {children}
        </div>
      </main>
    </div>
  );
};

export default Layout;
