import Sider from "antd/es/layout/Sider";
import React from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import MenuItems from "./MenuItems";
import clsx from "clsx";

const Aside = ({ isMobileMenu }) => {
  const navigate = useNavigate();

  return (
    <Sider className={clsx("aside", { mobile: isMobileMenu })}>
      <div className="branding">
        <img src={Logo} alt="Logo" onClick={() => navigate("/")} />
      </div>
      <div className="menu">
        <MenuItems />
      </div>
    </Sider>
  );
};

export default Aside;
