import { Menu } from "antd";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { routesArray } from "../../constants/Routes";

const MenuItems = () => {
  const location = useLocation();

  const isSubPath =
    location.pathname === `/cloud/${location.pathname.split("/").pop()}`;
  const currentPath = isSubPath ? "/cloud" : location.pathname;

  const menuItems = routesArray?.map((item) => ({
    key: item.path,
    label: (
      <Link className="menu-items" to={item.path}>
        {item.icon} {item.name}
      </Link>
    ),
  }));
  return (
    <Menu mode="inline" defaultSelectedKeys={[currentPath]} items={menuItems} />
  );
};

export default MenuItems;
