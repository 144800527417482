import { handleDeleteRequest, handleGetRequest, handlePutRequest } from ".";
import { URI } from "../../constants/URI";
import { setUser } from "../slices/userSlice";
import { store } from "../store";

export const getUserProfile = async () => {
  const res = await handleGetRequest(URI.user);
  if (res) store.dispatch(setUser(res.response));
};

export const updateUserProfile = async (data) => {
  await handlePutRequest(URI.update_profile, data);
  await getUserProfile();
};

export const deleteUser = async () => {
  await handleDeleteRequest(URI.delete_user);
};
