import React from "react";
import MediaCards from "../../components/Card";
import RecentFiles from "../../components/RecentFiles";

const Dashboard = () => {
  return (
    <div className="dashboard">
      <section className="analytics">
        <div className="section-title">Cloud Storage</div>
        <MediaCards />
      </section>

      {/* <section className="recent-files">
        <div className="section-title">Recent Files</div>
        <RecentFiles />
      </section> */}
    </div>
  );
};

export default Dashboard;
