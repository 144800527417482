import axios from "axios";
import { setAuth } from "../redux/slices/authSlice";
import { setUser } from "../redux/slices/userSlice";
import { store } from "../redux/store";
import { showErrorToast } from "../components/NotificationToast";

export const handleLogout = () => {
  store.dispatch(setAuth());
  localStorage.clear();
  window.location.href = "/";
};

export const getProfileImage = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_CS_APP_URL}/download-profile-picture`,
      {
        responseType: "arraybuffer",
        headers: {
          Authorization: store.getState().authSlice.auth,
        },
      }
    );

    const contentType = response.headers["content-type"];
    const blob = new Blob([response.data], { type: contentType });
    const url = URL.createObjectURL(blob);

    store.dispatch(
      setUser({ ...store.getState().userSlice, profile_image: url })
    );
  } catch (error) {
    showErrorToast(error.message || "Something went wrong");
  }
};

export const getImageByUrl = async (imageURL) => {
  try {
    const response = await axios.get(imageURL, {
      responseType: "arraybuffer",
      headers: {
        Authorization: store.getState().authSlice.auth,
      },
    });

    const contentType = response.headers["content-type"];
    const blob = new Blob([response.data], { type: contentType });
    const result = URL.createObjectURL(blob);
    return result;
  } catch (error) {
    showErrorToast(error.message || "Something went wrong");
  }
};

export const downloadAttachment = (blobUrl, name, ext) => {
  console.log("blobUrl, name, ext", blobUrl, name, ext);
  if (blobUrl) {
    const a = document.createElement("a");
    a.href = blobUrl;
    a.download = `${name}.${ext}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};
