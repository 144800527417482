import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  file_type: [],
  total_volume: 0,
  files: JSON.parse(localStorage.getItem("files")),
  sub_files: JSON.parse(localStorage.getItem("file")),
  enc_key: [],
};

const fileSlice = createSlice({
  name: "file",
  initialState,
  reducers: {
    setFileType: (state, action) => {
      state.file_type = action.payload;
    },
    setTotalVolume: (state, action) => {
      state.total_volume = action.payload;
    },
    setFiles: (state, action) => {
      localStorage.setItem("files", JSON.stringify(action.payload));
      state.files = action.payload;
    },
    setSubFiles: (state, action) => {
      localStorage.setItem("file", JSON.stringify(action.payload));
      state.sub_files = action.payload;
    },
    setEncKey: (state, action) => {
      state.enc_key = action.payload;
    },
  },
});

export const { setFileType, setTotalVolume, setFiles, setSubFiles, setEncKey } =
  fileSlice.actions;

export default fileSlice.reducer;
