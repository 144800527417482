import GoogleLogin from "@stack-pulse/next-google-login";
import { jwtDecode } from "jwt-decode";
import React from "react";
import { postGoogleUserData } from "../../../redux/action/googleAuth";
import { showErrorToast } from "../../../components/NotificationToast";

const GoogleAuth = () => {
  const clientId = `${process.env.REACT_APP_CLIENT_ID}`;

  const responseGoogle = (response) => {
    const authToken = jwtDecode(response.tokenObj.id_token);
    const body = {
      provider: "google",
      providerId: authToken.sub,
      authToken: response.tokenObj.id_token,
    };

    if (response) postGoogleUserData(body);
  };

  const onFailure = () => {
    showErrorToast("Login failed");
  };

  return (
    <GoogleLogin
      clientId={clientId}
      buttonText="Login with Google"
      onSuccess={responseGoogle}
      onFailure={onFailure}
      cookiePolicy={"single_host_origin"}
      className="google-login-btn"
    />
  );
};

export default GoogleAuth;
